// news.jsx - Represents a single news archive entry
import { graphql, Link } from 'gatsby';
import { arrayOf, shape, string, array } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { pictureFluidType } from '../../../types/picture';
import { Content } from '../../common/content';
import { FluidPicture } from '../../common/picture';
import { getLinkProps } from '../teaser/utils';
import './news.scss';

import dateformat from 'dateformat';

const bem = new BEMHelper({
  name: 'content-news',
});

export const News = ({
  id,
  newsEntry,
}) => {
  const { t } = useTranslation();

  //console.log(newsEntry);

  for(let i=0; i<newsEntry.length; i++){
    newsEntry[i]['element'] = [];
    newsEntry[i]['element']['__typename'] = newsEntry[i]['__typename'];
    newsEntry[i]['element']['slug'] = newsEntry[i]['slug'];
    if(typeof(newsEntry[i]['newsImage']) !== 'undefined'){
      newsEntry[i]['image'] = newsEntry[i]['newsImage'][0];
    }
  }

  return (
    <>
    {newsEntry.map((entry) => (
      <Content key={entry.slug} id={id} {...bem()} useOwnSpacing>

        {entry.image &&
        <FluidPicture
          {...entry.image}
          maxWidth={180}
          widths={[180]}
          role="presentation"
          {...bem('image')}
        />
        }
        <div {...bem('textcontainer')}>
          <h2 {...bem('headline')}>
            <Link
              {...bem('link')}
              {...getLinkProps(entry)}>
              {entry.title}
            </Link>
          </h2>
          <div {...bem('date')}>{ dateformat(entry.publishedDate, `fullDate`) }</div>
          <div {...bem('excerpt')}>{ parse(entry.pageExcerpt) }</div>
        </div>

      </Content>
    ))}
    </>
  );
};

News.propTypes = {
  id: string.isRequired,
  //headline: string.isRequired,
  //image: arrayOf(pictureFluidType).isRequired,
  newsEntry: array.isRequired,
};

export const fragment = graphql`
  fragment NewsContentFragment on CraftGQL_pageContents_news_BlockType {
    id
    newsEntry {
      ...on CraftGQL_news_news_Entry {
        __typename
        title
        slug: pageSlug
        publishedDate
        pageExcerpt
        newsImage {
          ...Picture
          fluid_0: url(width: 180, mode: "crop", position: "") # 0.25
        }
        departments: pageDepartments {
          slug
          ...on CraftGQL_departments_department_Entry {
            color: departmentColor
          }
        }
      }
    }
  }
`;
/*
url
      text
      target
      title
      element {
        ...on CraftGQL_pages_pages_Entry {
          slug: pageSlug
          departments: pageDepartments {
            slug
            ...on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }
        }

        ...on CraftGQL_departments_department_Entry {
          slug,
          color: departmentColor
        }

        ...on CraftGQL_products_product_Entry {
          slug
          departments: productDepartment {
            ...on CraftGQL_departments_department_Entry {
              slug
              color: departmentColor
            }
          }

          variant: productVariants(limit: 1) {
            ...on CraftGQL_variants_variants_Entry {
              slug
            }
          }
        }
      }
 */
